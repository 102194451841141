<template>
  <v-container>
    <v-form v-model="isFormValid" ref="form">
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Nowe hasło*</h3>
          <v-text-field
            v-model="newPassword"
            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="validators.passwordRules"
            @click:append="showNewPassword = !showNewPassword"
            :type="showNewPassword ? 'text' : 'password'"
            name="newPassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <h3 class="text font-weight-bold mb-4">Powtórz hasło*</h3>
          <v-text-field
            v-model="confirmationPassword"
            :append-icon="showConfirmationPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showConfirmationPassword = !showConfirmationPassword"
            :rules="[
              ...validators.passwordRules,
              (value) => validators.samePasswordRule(value, newPassword),
            ]"
            :type="showConfirmationPassword ? 'text' : 'password'"
            name="confirmationPassword"
            outlined
            solo
            elevation="0"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0" cols="12" sm="6" md="6">
          <primary-button :onClick="changePassword" :loading="loading">{{
            buttonText
          }}</primary-button>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import PrimaryButton from './buttons/PrimaryButton.vue';
import { validators } from '../validators/Validators';

export default {
  components: { PrimaryButton },
  name: 'ChangePassword',
  props: ['buttonText'],
  data() {
    return {
      isFormValid: false,
      newPassword: '',
      confirmationPassword: '',
      showNewPassword: false,
      showConfirmationPassword: false,
      loading: false,
    };
  },
  methods: {
    async changePassword() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        const token = this.$route.params.token;
        this.loading = true;
        try {
          await this.$store.dispatch('auth/resetPassword', {
            token,
            newPassword: this.newPassword,
            confirmationPassword: this.confirmationPassword,
          });
          this.$router.push({ name: 'PasswordChanged' });
        } catch (error) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    validators() {
      return validators;
    },
  },
};
</script>
<style lang="scss" scoped></style>
