<template>
  <title-wrapper title="Zmień hasło dla swojego konta">
    <v-container class="white pa-10">
      <set-account-password button-text="Zmień hasło"></set-account-password>
    </v-container>
  </title-wrapper>
</template>
<script>
import SetAccountPassword from '../../components/SetAccountPassword.vue';
import TitleWrapper from '../../components/TitleWrapper.vue';
export default {
  components: { TitleWrapper, SetAccountPassword },
};
</script>
<style lang="scss" scoped></style>
